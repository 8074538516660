import { createSlice } from "@reduxjs/toolkit";

const labSlice = createSlice({
    name: "lab",
    initialState: {
        labs: [],
        loading: false,
        error: null,
        labToShow: null,
        labID: null,
        labCategories: [],
        launchLink: null,
        subscriptionId: 2070870,
        planID: 11235,
        companyID: 11230,
        teamID: 11234,
        fastLaneData: [],
        fastLaneCategories: [],
        fastLaneEvents: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        getLabs: (state, action) => {
            state.labs = action.payload;
        },
        getLabById: (state, action) => {
            state.labToShow = action.payload;
        },
        setLabId: (state, action) => {
            state.labID = action.payload;
        },
        getLabCategories: (state, action) => {
            state.labCategories = action.payload;
        },
        setLaunchLink: (state, action) => {
            state.launchLink = action.payload;
        },

        setFastLaneData: (state, action) => {
            state.fastLaneData = [...state.fastLaneData, ...action.payload];
        },
        setFastLaneCategories: (state, action) => {
            state.fastLaneCategories = action.payload;
        },

        setFastLaneEvents: (state, action) => {
            state.fastLaneEvents = [...state.fastLaneEvents, ...action.payload];
        }
    },
});

export const { setLoading, getLabs, getLabById, setLabId, getLabCategories, setLaunchLink, setFastLaneData, setFastLaneCategories, setFastLaneEvents } = labSlice.actions;
export default labSlice.reducer;