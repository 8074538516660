import React from 'react'
import { Container } from 'react-bootstrap'
import { careersContent } from './careersContent'
import MetaTag from '../component/Common/MetaTag.tsx'
import { type } from '@testing-library/user-event/dist/type/index'

export default function Careers() {
    return (
        <>
            <MetaTag
            // title='Jobs & Careers at Q Academy | Empower Learners, Shape the Future' 
            // description='Join us at Q Academy in our mission to provide top-tier courses for all, and embark on your journey toward a fulfilling career today. Explore Job Openings!'
            // keywords='Job Openings at Q Academy'
            />
            <Container className='mt-5'>
                <h2 className='mb-5 mt-5' >Available Jobs</h2>
                {
                    careersContent.map((career, i) => (
                        <div className='mb-5'>
                            <h3>{career.jobTitle}</h3>
                            {
                                career.description.map((description, i) => (
                                    <>
                                        <h4 className='mt-3'>{description.title}</h4>
                                        {
                                            typeof description.description === 'string' ? <p>{description.description}</p> : typeof description.description === 'object' && <ul className='mt-3 mb-3'>
                                                {
                                                    description.description.map((description, i) => (
                                                        <li key={i} >{description}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        {
                                            description?.visit && <p dangerouslySetInnerHTML={{ __html: description.visit }}></p>
                                        }
                                    </>
                                ))
                            }
                            <h4>Responsibilities :</h4>
                            <ul className='mt-3 mb-3'>
                                {
                                    career.responsibilities.map((responsibility, i) => (
                                        <li key={i} className='mt-3' >
                                            <h5 className={['', undefined, null, 0].includes(responsibility?.description?.length) && 'text-secondary'} >{responsibility.title}</h5>
                                            <ul>
                                                {
                                                    responsibility?.description?.length > 0 && responsibility?.description.map((description, i) => (
                                                        <li key={i} >{description}</li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))
                                }
                            </ul>
                            <h4>{career.qualifications?.length > 0 && 'Qualifications :'}</h4>
                            <ul>
                                {
                                    career.qualifications?.map((qualification, i) => (
                                        <li key={i} >
                                            <p>{qualification}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            <p>{career.application}</p>
                            {i !== careersContent.length - 1 &&
                                <hr style={{
                                    border: '1px solid #000000',
                                }} className='mt-5' />
                            }
                        </div>
                    ))
                }
            </Container>
        </>
    )
}
