
/**
 * boiler plate for careers content array object
 {
   jobTitle: "",
  description: [
     {
        title: "",
       description: ""
  }
],
responsibilities: [
  {
    title: "",
  description: [
    ""
 ]
}
],
qualifications: [
 ""
],
application: ""
}
 * 
 */

export const careersContent = [
    {
        jobTitle: "Regional/State Project Head (BD & Operations)",
        description: [
            {
                title: "The position will be responsible for project implementation and delivery and pursuing new skill development business development opportunities emerging in the assigned region.",
                description: [
                    "Actively monitor the Skilling development to identify and pitch for new Skill Development projects within the assigned region.",
                    "Prepare the project implementation roadmap and action plans for implementation of the new and on-going projects awarded in the region as per Scheme and Project mandate and prescribed guidelines.",
                    "Plan and establish new training centers or identify local partner centers in line with mandates received for assigned region.",
                    "Prepare the CAPEX and OPEX budgets and revenue forecast ahead of starting new projects by coordinating the finance and procurement teams and monitor the spend diligently.",
                    "Identify and recruit Training Center teams as per the project implementation plans on existing mandates to ensure timebound achievement of targets allocated under different projects.",
                    "Monitoring and review of the documentation and ensure data entry on concerned portals and internal MIS/ ERP systems and specific Government portals as per Scheme of Project SOPs.",
                    "Regular Training center visit and conducting periodic reviews/ audits as per guidelines prescribed for the project.",
                    "Ensure all the statutory, legal and financial compliances are being met as per the project and organizations internal requirement.",
                    "Ensure timely submission of reports and ensure measures to track budget and spending.",
                    "Address the operational and management crisis at the center and suitably escalate critical issues to the knowledge of leadership team.",
                ]
            },
            {
                title: "Sales Manager - B2B, Ranchi.",
            }
        ],
        responsibilities: [
            {
                title: "Sales Manager - B2B, Ranchi",
                description: [
                    "Drive revenue growth within assigned sales territory, focusing on B2B clientele.",
                    "Develop and execute strategies tailored to meeting sales targets in the educational technology sector.",
                    "Strategize for future business expansion and market penetration.",
                    "Identify and engage potential clients within the education industry, generating leads and creating new business opportunities.",
                    "Spearhead brand promotion efforts, including strategic placement of marketing materials at relevant educational events and institutions.",
                    "Cultivate and strengthen relationships with educational institutions and corporate clients.",
                    "Maintain accurate and comprehensive data and records for future reference and analysis.",
                ]
            }
        ],
    },
    {
        jobTitle: "Sales-Driven Performance Marketer",
        description: [
            {
                title: 'Overview :',
                description: "We are in search of a dynamic and results-oriented Sales-Driven Performance Marketer to join our passionate team at Q Academy. In this role, you will be a key player in spearheading marketing initiatives that not only boost user acquisition and engagement but also contribute directly to driving sales growth. The ideal candidate should have a proven track record of successfully integrating performance marketing with a sales-focused approach in the education technology sector.",
            },
        ],
        responsibilities: [
            {
                title: 'Sales-Focused Campaign Strategy and Execution',
                description: [
                    "Develop and execute comprehensive performance marketing campaigns strategically designed to drive both user acquisition and sales conversion.",
                    "Collaborate closely with the sales team to align marketing initiatives with revenue targets and product launches."
                ]
            },
            {
                title: 'Organic and Paid Advertising for Sales Impact',
                description: [
                    "Manage and optimize the advertising channels, including Google Ads, Facebook Ads, LinkedIn Ads, and others, with a primary focus on driving sales.",
                    "Analyze and adjust bidding strategies, targeting parameters, and ad creatives to maximize ROI and sales performance."
                ]
            },
            {
                title: 'Data-Driven Sales Insights',
                description: [
                    "Utilize analytics tools to track, analyze, and report on key performance metrics, with a specific emphasis on sales metrics.",
                    "Provide insights and recommendations based on performance data to enhance both marketing and sales strategies."
                ]
            },
            {
                title: 'SEO and Content Marketing for Sales Enhancement',
                description: [
                    "Collaborate with the content team to optimize website content for search engines with a sales-driven mindset.",
                    "Implement SEO best practices to improve organic search rankings, ultimately contributing to increased sales."
                ]
            },
            {
                title: 'Sales-Centric Conversion Rate Optimization (CRO)',
                description: [
                    "Conduct A/B testing and implement CRO strategies to optimize landing pages and improve conversion rates, emphasizing sales conversion.",
                    "Continuously identify areas for improvement in the user journey that directly impact sales and implement solutions."
                ]
            },
            {
                title: 'Budget Management Aligned with Sales Goals',
                description: [
                    "Manage marketing budgets with a primary focus on achieving sales targets, ensuring cost efficiency and optimal resource allocation.",
                    "Monitor and report on budget performance, adjusting strategies as needed to meet sales objectives."
                ]
            }
        ],
        qualifications: [
            "Bachelor's degree in marketing, business, or a related field",
            "Proven experience in performance marketing, specifically with a focus on driving sales, preferably in the EdTech industry.",
            "Strong analytical skills with the ability to interpret data and trends related to sales metrics.",
            "Proficiency in using marketing analytics tools and platforms.",
            "Experience with SEO best practices and content optimization with a focus on driving sales. ",
            "Excellent communication and collaboration skills.",
        ],
        application: "If you are passionate about leveraging performance marketing to drive sales growth in the EdTech sector, we invite you to apply and join our dedicated team. Let's empower learners and achieve unprecedented sales success in shaping the future of education together!"
    },

    // demo job ⤵️

    {
        jobTitle: "Vice President of Sales",
        description: [
            {
                title: 'Overview :',
                description: "We are seeking a dynamic and experienced Vice President of Sales to lead and drive our sales strategy in the fast-evolving space. The ideal candidate will be a strategic thinker, adept at building and managing high-performing sales teams, and capable of developing and executing sales initiatives that align with our company's growth objectives.",
            },
            {
                title: 'The Company',
                description: "QAcademy, a wing of MSM,atechnology-focused learning institution that focuses on customized hands-on training. We promote industry-driven education through a comprehensive roster of courses in coding and web development, Microsoft, computer fundamentals, graphic design, and other digital programs. The industry leader could confidentially pick any training professional to plug in the growth segment of their industry.",
                visit: `To know more about us, visit <a href="https://qacademy.ca/" target="_blank">https://qacademy.ca/</a> and <a href="https://qrangers.com/" target="_blank">https://qrangers.com/</a>`,
            }
        ],

        responsibilities: [
            {
                title: 'Develop and execute the overall sales strategy, including setting revenue targets, forecasting, and creating sales plans.',
            },
            {
                title: ' Provide visionary leadership to the sales team, fostering a high-performance culture and ensuring alignment with organizational goals.',
            },
            {
                title: 'Recruit, train, and mentor a skilled and motivated sales team.',
            },
            {
                title: 'Conduct thorough market analysis to identify opportunities, market trends, and potential challenges.',
            },
            {
                title: 'Implement retention strategies to ensure long-term customer satisfaction and maximize customer lifetime value.',
            },
            {
                title: 'Collaborate with the marketing team to align sales and marketing efforts, ensuring a cohesive approach to lead generation and customer acquisition.',
            },
            {
                title: 'Utilize analytics tools to track and analyze key sales performance metrics.',
            },
            {
                title: 'Regularly report on sales performance, providing insights and recommendations for improvement.',
            },
            {
                title: 'Identify and cultivate strategic partnerships to expand the reach and impact of sales efforts.',
            },
            {
                title: 'Collaborate with cross-functional teams to explore new business opportunities and revenue streams.',
            },
        ],
        qualifications: [
            "Proven experience in a senior sales leadership role within the EdTech Ind",
            "Strong track record of achieving and exceeding sales targets.",
            "Excellent leadership and team management skills.",
            "Strategic thinker with the ability to develop and execute sales strategies.",
            "Exceptional communication and presentation skills.",
            "Bachelor's degree in Business, Marketing, or a related field; MBA is a plus.",
        ],
        application: "If you are a strategic sales leader with a passion for transforming education through technology, we invite you to apply for the position of Vice President of Sales and join us in shaping the future of EdTech."
    },

]
