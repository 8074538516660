import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchCountryInfo } from '../services/Services';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id');
  const reset_key = searchParams.get('reset_key');

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    if (!newPassword.trim()) {
      setError('Please Enter Password');
      setLoading(false);
      return;
    }
    if (!confirmPassword.trim()) {
      setError('Please Enter Confirm Password');
      setLoading(false);
      return;
    }
    // check length of password 8>password<72
    if (newPassword.trim().length < 8 || newPassword.trim().length > 72) {
      setError('Password must be between 8 and 72 characters');
      setLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('The password and confirm password fields do not match.');
      setLoading(false);
      return;
    }
    try {
      const baseUrl = await fetchCountryInfo();
      const uri = `${baseUrl}/wp-json/lmscourses/v2/change-password?newPassword=${newPassword}&confirmPassword=${confirmPassword}&user_id=${user_id}&reset_key=${reset_key}`;
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setLoading(false);
      if (data.message === 'Password updated successfully') {
        setResponse({
          type: 'success',
          msg: data.message,
        });
        return;
      }
      setResponse({
        type: 'danger',
        msg: data.message,
      });

    } catch (error) {
      setLoading(false);
      setError('Invalid Username or Password');
    }
  }
  const isMobile = useMediaQuery({ query: '(max-width: 694px)' })
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <nav className={`w-100 p-2`} style={{ backgroundColor: '#05295D', height: '40px' }}>
        <Container className={`${isMobile && 'd-flex justify-content-center'}`} >
          <Link className="navbar-brand fs-lg-6 text-white" to="/" style={{
            fontSize: '12px',
            textDecoration: 'none'
          }} >Have any question? 1800 532 272 588 info@qacademy.com</Link>
        </Container>
      </nav>
      <nav className={`w-100 p-2`} style={{
        height: '80px',
        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2)`,
        marginBottom: '40px',
      }}>
        <Container className={`${isMobile && 'd-flex justify-content-center'}`} >
          <Link to="/" className='navbar-brand'><img style={{
            width: '200px',
            marginTop: '10px',
            objectFit: 'contain',
          }} className="w-75-sm" src="/assets/images/logo.webp" alt="" /></Link>
        </Container>
      </nav>
      <div className='container'>
        {response && <div class={`alert alert-${response.type}`} role="alert">
          {`${response?.msg}!`} {response.type === 'success' ? <Link to='/'>Login</Link> : ''}
        </div>}
      </div>

      {/* <p>Setting up password for:<span>examle@gmail.com</span></p> */}
      <Container>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Col lg='6' sm='12' md='6'>
            <Form onSubmit={handleResetPassword}>
              <center>
                <p className='mb-4 text-dark' style={{ fontFamily: 'Roboto', fontSize: 35 }}>Reset Password</p>
              </center>
              <Form.Group className="mb-3 " controlId="password">
                <Form.Label className=' text-dark' style={{ fontFamily: 'Roboto' }}>Password</Form.Label>
                <Form.Label className='ms-4' style={{ fontFamily: 'Roboto' }}>(Between 8 to 72 characters)</Form.Label>
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter your password"
                  onInput={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label className=' text-dark' style={{ fontFamily: 'Roboto' }}>Confirm Password</Form.Label>
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Re-Enter your password"
                  onInput={(e) => setConfirmPassword(e.target.value)}
                />
                {error && <Form.Text className="text-danger">
                  {error}
                </Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Show Password" onClick={() => setShowPassword(!showPassword)} />
              </Form.Group>
              <Button type="submit" className="w-100">
                {loading ? 'Loading...' : 'Reset Password'}
              </Button>
            </Form>
          </Col>
        </div>
      </Container>
    </>
  );
}